/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MapViewerClient,
  PatchedMapViewerClientOptional,
} from '../models';
import {
    MapViewerClientFromJSON,
    MapViewerClientToJSON,
    PatchedMapViewerClientOptionalFromJSON,
    PatchedMapViewerClientOptionalToJSON,
} from '../models';

export interface ApiGrexsuperadminClientMapviewerSettingsCreateRequest {
    clientId: number;
    mapViewerClient: MapViewerClient;
}

export interface ApiGrexsuperadminClientMapviewerSettingsPartialUpdateRequest {
    clientId: number;
    patchedMapViewerClientOptional?: PatchedMapViewerClientOptional;
}

export interface ApiGrexsuperadminClientMapviewerSettingsRetrieveRequest {
    clientId: number;
}

export interface ApiGrexsuperadminClientMapviewerSettingsUpdateRequest {
    clientId: number;
    mapViewerClient: MapViewerClient;
}

/**
 * 
 */
export class GrexSuperAdminMapViewerApi extends runtime.BaseAPI {

    /**
     * Endpoint for linking a MapViewer client to a GrexManager client
     */
    async apiGrexsuperadminClientMapviewerSettingsCreateRaw(requestParameters: ApiGrexsuperadminClientMapviewerSettingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientMapviewerSettingsCreate.');
        }

        if (requestParameters.mapViewerClient === null || requestParameters.mapViewerClient === undefined) {
            throw new runtime.RequiredError('mapViewerClient','Required parameter requestParameters.mapViewerClient was null or undefined when calling apiGrexsuperadminClientMapviewerSettingsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/mapviewer-settings/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MapViewerClientToJSON(requestParameters.mapViewerClient),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for linking a MapViewer client to a GrexManager client
     */
    async apiGrexsuperadminClientMapviewerSettingsCreate(requestParameters: ApiGrexsuperadminClientMapviewerSettingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientMapviewerSettingsCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for partially updating a MapViewer client
     */
    async apiGrexsuperadminClientMapviewerSettingsPartialUpdateRaw(requestParameters: ApiGrexsuperadminClientMapviewerSettingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapViewerClient>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientMapviewerSettingsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/mapviewer-settings/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMapViewerClientOptionalToJSON(requestParameters.patchedMapViewerClientOptional),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapViewerClientFromJSON(jsonValue));
    }

    /**
     * Endpoint for partially updating a MapViewer client
     */
    async apiGrexsuperadminClientMapviewerSettingsPartialUpdate(requestParameters: ApiGrexsuperadminClientMapviewerSettingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapViewerClient> {
        const response = await this.apiGrexsuperadminClientMapviewerSettingsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a MapViewer client to a GrexManager client
     */
    async apiGrexsuperadminClientMapviewerSettingsRetrieveRaw(requestParameters: ApiGrexsuperadminClientMapviewerSettingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapViewerClient>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientMapviewerSettingsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/mapviewer-settings/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapViewerClientFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving a MapViewer client to a GrexManager client
     */
    async apiGrexsuperadminClientMapviewerSettingsRetrieve(requestParameters: ApiGrexsuperadminClientMapviewerSettingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapViewerClient> {
        const response = await this.apiGrexsuperadminClientMapviewerSettingsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a MapViewer client
     */
    async apiGrexsuperadminClientMapviewerSettingsUpdateRaw(requestParameters: ApiGrexsuperadminClientMapviewerSettingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapViewerClient>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientMapviewerSettingsUpdate.');
        }

        if (requestParameters.mapViewerClient === null || requestParameters.mapViewerClient === undefined) {
            throw new runtime.RequiredError('mapViewerClient','Required parameter requestParameters.mapViewerClient was null or undefined when calling apiGrexsuperadminClientMapviewerSettingsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/mapviewer-settings/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MapViewerClientToJSON(requestParameters.mapViewerClient),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapViewerClientFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a MapViewer client
     */
    async apiGrexsuperadminClientMapviewerSettingsUpdate(requestParameters: ApiGrexsuperadminClientMapviewerSettingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapViewerClient> {
        const response = await this.apiGrexsuperadminClientMapviewerSettingsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
