import LoadingOverlay from "@shared/components/LoadingOverlay";
import MessagePopup from "@shared/components/MessagePopup";
import React from "react";
import "./App.css";
import { AppStateHandler } from "./AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "./Types";
import MenuBar from "./components/MenuBar";
import LoginMfaScreen from "./screens/auth/LoginMfaScreen";
import LoginScreen from "./screens/auth/LoginScreen";
import AddModelTemplateScreen from "./screens/clients/AddModelTemplateScreen";
import AddReportTemplateScreen from "./screens/clients/AddReportTemplateScreen";
import ClientLogScreen from "./screens/clients/ClientLogScreen";
import ClientsScreen from "./screens/clients/ClientsScreen";
import CreateClientScreen from "./screens/clients/CreateClientScreen";
import EditClientScreen from "./screens/clients/EditClientScreen";
import EditModelTemplateScreen from "./screens/clients/EditModelTemplateScreen";
import EditReportTemplateScreen from "./screens/clients/EditReportTemplateScreen";
import EditSubregionScreen from "./screens/clients/EditSubregionScreen";
import SamlClientScreen from "./screens/clients/SamlClientScreen";
import DocumentationListScreen from "./screens/docs/DocumentationListScreen";
import DocumentationScreen from "./screens/docs/DocumentationScreen";
import NewDocumentationScreen from "./screens/docs/NewDocumentationScreen";
import LogScreen from "./screens/log/LogScreen";
import SetupMfaScreen from "./screens/user/SetupMfaScreen";
import MyAccountScreen from "./screens/user/UserScreen";
export default class App extends React.Component {
  stateHandler = new AppStateHandler();
  state: AppStateType;

  constructor(props: any) {
    super(props);
    this.state = this.stateHandler.getState();
  }

  componentDidMount() {
    this.stateHandler.initApp((newState) => {
      this.setState(newState);
    });
  }

  render() {
    return (
      <>
        {(() => {
          if (!this.state.loggedIn) {
            return (
              <LoginScreen
                state={this.state}
                stateHandler={this.stateHandler}
                updateStateCallback={(newState) => {
                  this.setState(newState);
                }}
              ></LoginScreen>
            );
          } else if (this.state.mfaRequired) {
            return (
              <LoginMfaScreen
                state={this.state}
                stateHandler={this.stateHandler}
                updateStateCallback={(newState) => {
                  this.setState(newState);
                }}
              ></LoginMfaScreen>
            );
          } else {
            return (
              <div id="App-Container">
                {(() => {
                  if (this.state.loading) {
                    return <LoadingOverlay />;
                  }
                })()}

                {(() => {
                  if (
                    this.state.popUpState ===
                    PopUpState.DeleteReportTemplateAlert
                  ) {
                    return (
                      <MessagePopup
                        massageTitle="Waarschuwing!"
                        message={
                          "Weet u zeker dat u dit sjabloon wilt verwijderen?"
                        }
                        okayCallback={() => {
                          this.stateHandler.permanentlyDeleteReportTemplate(
                            (newState) => {
                              this.setState(newState);
                            }
                          );
                        }}
                        cancelCallback={() => {
                          this.stateHandler.closeAlert((newState) => {
                            this.setState(newState);
                          });
                        }}
                      ></MessagePopup>
                    );
                  } else if (
                    this.state.popUpState ===
                    PopUpState.DeleteModelTemplateAlert
                  ) {
                    return (
                      <MessagePopup
                        massageTitle="Waarschuwing!"
                        message={
                          "Weet u zeker dat u dit sjabloon wilt verwijderen? Alle rekenmodellen die gebaseerd zijn op dit sjabloon zullen ook verwijdert worden."
                        }
                        okayCallback={() => {
                          this.stateHandler.permanentlyDeleteModelTemplate(
                            (newState) => {
                              this.setState(newState);
                            }
                          );
                        }}
                        cancelCallback={() => {
                          this.stateHandler.closeAlert((newState) => {
                            this.setState(newState);
                          });
                        }}
                      ></MessagePopup>
                    );
                  }
                })()}

                <MenuBar
                  state={this.state}
                  stateHandler={this.stateHandler}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                ></MenuBar>
                <div className="App-Screen">
                  {(() => {
                    if (this.state.screenState === ScreenState.Clients) {
                      return (
                        <ClientsScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        ></ClientsScreen>
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditClient
                    ) {
                      return (
                        <EditClientScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        ></EditClientScreen>
                      );
                    } else if (this.state.screenState === ScreenState.Logs) {
                      return (
                        <LogScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.ClientLog
                    ) {
                      return (
                        <ClientLogScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (this.state.screenState === ScreenState.User) {
                      return (
                        <MyAccountScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.SetupMfa
                    ) {
                      return (
                        <SetupMfaScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.CreateClient
                    ) {
                      return (
                        <CreateClientScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.AddModelTemplate
                    ) {
                      return (
                        <AddModelTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.AddReportTemplate
                    ) {
                      return (
                        <AddReportTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditReportTemplate
                    ) {
                      return (
                        <EditReportTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditModelTemplate
                    ) {
                      return (
                        <EditModelTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.DocumentationList
                    ) {
                      return (
                        <DocumentationListScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditDocumentation
                    ) {
                      return (
                        <DocumentationScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.NewDocumentation
                    ) {
                      return (
                        <NewDocumentationScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditSubregion
                    ) {
                      return (
                        <EditSubregionScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.SamlClient
                    ) {
                      return (
                        <SamlClientScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    }
                  })()}
                </div>
              </div>
            );
          }
        })()}
      </>
    );
  }
}
