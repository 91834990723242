import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import ButtonList from "@shared/components/ButtonList";
import FileInput from "@shared/components/FileInput";
import Section from "@shared/components/Section";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import { formatDate } from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";
import "./EditModelTemplateScreen.css";

interface EditModelTemplateScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class EditModelTemplateScreen extends React.Component<EditModelTemplateScreenProps> {
  render() {
    return (
      <div id="EditModelTemplateScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klant bewerken",
              callback: () => {
                if (this.props.state.clientEditScreen.id) {
                  this.props.stateHandler.edit_client_init_screen(
                    this.props.state.clientEditScreen.id,
                    this.props.updateStateCallback
                  );
                }
              },
            },
            {
              title: "Sjabloon voor rekenmodellen bewerken",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={false}>
          {(() => {
            if (this.props.state.editModelTemplateScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.editModelTemplateScreen.formError}
                ></Alert>
              );
            }
            if (
              this.props.state.editModelTemplateScreen.successMessage !== ""
            ) {
              return (
                <Alert
                  Error={false}
                  Message={
                    this.props.state.editModelTemplateScreen.successMessage
                  }
                ></Alert>
              );
            }
          })()}
          <table className="EditModelTemplateScreen-Table">
            <tbody>
              <tr>
                <td>Zichtbare naam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Zichtbare naam"
                    onChange={(newValue) => {
                      this.props.stateHandler.updateEditModelTemplateScreen(
                        { displayName: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.editModelTemplateScreen.displayName}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newValue) => {
                      this.props.stateHandler.updateEditModelTemplateScreen(
                        { description: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.editModelTemplateScreen.description}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.saveUpdateEditModelTemplateScreen(
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Opslaan
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section disabled={false}>
          <table className="EditModelTemplateScreen-Table">
            <tbody>
              <tr>
                <td>Datasheet naam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Datasheet naam"
                    onChange={(newValue) => {
                      this.props.stateHandler.updateEditModelTemplateScreen(
                        { datasheetName: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={
                      this.props.state.editModelTemplateScreen.datasheetName
                    }
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Sjabloon toevoegen*</td>
                <td>
                  <FileInput
                    onChange={(event) => {
                      this.props.stateHandler.updateEditModelTemplateScreen(
                        { files: event.target.files },
                        this.props.updateStateCallback
                      );
                    }}
                  ></FileInput>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.saveEditModelTemplateScreen(
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Upload
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section disabled={false}>
          <table className="EditModelTemplateScreen-Table">
            <thead>
              <tr>
                <th>Upload datum</th>
                <th>Bestandsname</th>
                <th>Actief</th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.editModelTemplateScreen.history.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{formatDate(item.timestampUpload)}</td>
                    <td>{item.filenameOrig}</td>
                    <td
                      onClick={(event) => {
                        this.props.stateHandler.activateModelTemplateScreen(
                          item.id,
                          this.props.updateStateCallback
                        );
                      }}
                      className="EditModelTemplateScreen-ActiveButton"
                    >
                      {item.active ? "v" : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Sjablonen volledig verwijderen",
              callback: () => {
                this.props.state.popUpState =
                  PopUpState.DeleteModelTemplateAlert;
                this.props.updateStateCallback(this.props.state);
              },
              disabled: false,
            },
            {
              title: "Annuleren",
              callback: () => {
                if (this.props.state.clientEditScreen.id) {
                  this.props.stateHandler.edit_client_init_screen(
                    this.props.state.clientEditScreen.id,
                    this.props.updateStateCallback
                  );
                }
              },
              disabled: false,
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
