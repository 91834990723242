/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckCreateNameRes,
  Client,
  ClientCreateUpdateDelete,
  GrexUser,
  GrexUserCreate,
} from '../models';
import {
    CheckCreateNameResFromJSON,
    CheckCreateNameResToJSON,
    ClientFromJSON,
    ClientToJSON,
    ClientCreateUpdateDeleteFromJSON,
    ClientCreateUpdateDeleteToJSON,
    GrexUserFromJSON,
    GrexUserToJSON,
    GrexUserCreateFromJSON,
    GrexUserCreateToJSON,
} from '../models';

export interface ApiGrexsuperadminClientCheckRetrieveRequest {
    name: string;
}

export interface ApiGrexsuperadminClientCreateRequest {
    clientCreateUpdateDelete: ClientCreateUpdateDelete;
}

export interface ApiGrexsuperadminClientDestroyRequest {
    id: number;
}

export interface ApiGrexsuperadminClientRetrieveRequest {
    id: number;
}

export interface ApiGrexsuperadminClientUsersCreateRequest {
    id: number;
    grexUserCreate: GrexUserCreate;
}

export interface ApiGrexsuperadminClientUsersListRequest {
    id: number;
    role?: ApiGrexsuperadminClientUsersListRoleEnum;
}

/**
 * 
 */
export class GrexSuperAdminClientApi extends runtime.BaseAPI {

    /**
     * Check if client name is available
     */
    async apiGrexsuperadminClientCheckRetrieveRaw(requestParameters: ApiGrexsuperadminClientCheckRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckCreateNameRes>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiGrexsuperadminClientCheckRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/check/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckCreateNameResFromJSON(jsonValue));
    }

    /**
     * Check if client name is available
     */
    async apiGrexsuperadminClientCheckRetrieve(requestParameters: ApiGrexsuperadminClientCheckRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckCreateNameRes> {
        const response = await this.apiGrexsuperadminClientCheckRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create client
     */
    async apiGrexsuperadminClientCreateRaw(requestParameters: ApiGrexsuperadminClientCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientCreateUpdateDelete>> {
        if (requestParameters.clientCreateUpdateDelete === null || requestParameters.clientCreateUpdateDelete === undefined) {
            throw new runtime.RequiredError('clientCreateUpdateDelete','Required parameter requestParameters.clientCreateUpdateDelete was null or undefined when calling apiGrexsuperadminClientCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientCreateUpdateDeleteToJSON(requestParameters.clientCreateUpdateDelete),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCreateUpdateDeleteFromJSON(jsonValue));
    }

    /**
     * create client
     */
    async apiGrexsuperadminClientCreate(requestParameters: ApiGrexsuperadminClientCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientCreateUpdateDelete> {
        const response = await this.apiGrexsuperadminClientCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete user
     */
    async apiGrexsuperadminClientDestroyRaw(requestParameters: ApiGrexsuperadminClientDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * delete user
     */
    async apiGrexsuperadminClientDestroy(requestParameters: ApiGrexsuperadminClientDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexsuperadminClientDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * load clients list
     */
    async apiGrexsuperadminClientListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Client>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientFromJSON));
    }

    /**
     * load clients list
     */
    async apiGrexsuperadminClientList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Client>> {
        const response = await this.apiGrexsuperadminClientListRaw(initOverrides);
        return await response.value();
    }

    /**
     * load user
     */
    async apiGrexsuperadminClientRetrieveRaw(requestParameters: ApiGrexsuperadminClientRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     * load user
     */
    async apiGrexsuperadminClientRetrieve(requestParameters: ApiGrexsuperadminClientRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Client> {
        const response = await this.apiGrexsuperadminClientRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create client user
     */
    async apiGrexsuperadminClientUsersCreateRaw(requestParameters: ApiGrexsuperadminClientUsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GrexUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientUsersCreate.');
        }

        if (requestParameters.grexUserCreate === null || requestParameters.grexUserCreate === undefined) {
            throw new runtime.RequiredError('grexUserCreate','Required parameter requestParameters.grexUserCreate was null or undefined when calling apiGrexsuperadminClientUsersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/users/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GrexUserCreateToJSON(requestParameters.grexUserCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GrexUserFromJSON(jsonValue));
    }

    /**
     * create client user
     */
    async apiGrexsuperadminClientUsersCreate(requestParameters: ApiGrexsuperadminClientUsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GrexUser> {
        const response = await this.apiGrexsuperadminClientUsersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * load client users
     */
    async apiGrexsuperadminClientUsersListRaw(requestParameters: ApiGrexsuperadminClientUsersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrexUser>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientUsersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/users/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrexUserFromJSON));
    }

    /**
     * load client users
     */
    async apiGrexsuperadminClientUsersList(requestParameters: ApiGrexsuperadminClientUsersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrexUser>> {
        const response = await this.apiGrexsuperadminClientUsersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ApiGrexsuperadminClientUsersListRoleEnum = {
    Adm: 'ADM',
    Non: 'NON',
    Usr: 'USR',
    Vwr: 'VWR'
} as const;
export type ApiGrexsuperadminClientUsersListRoleEnum = typeof ApiGrexsuperadminClientUsersListRoleEnum[keyof typeof ApiGrexsuperadminClientUsersListRoleEnum];
