/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckCreateNameRes
 */
export interface CheckCreateNameRes {
    /**
     * 
     * @type {boolean}
     * @memberof CheckCreateNameRes
     */
    available: boolean;
}

/**
 * Check if a given object implements the CheckCreateNameRes interface.
 */
export function instanceOfCheckCreateNameRes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "available" in value;

    return isInstance;
}

export function CheckCreateNameResFromJSON(json: any): CheckCreateNameRes {
    return CheckCreateNameResFromJSONTyped(json, false);
}

export function CheckCreateNameResFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckCreateNameRes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available': json['available'],
    };
}

export function CheckCreateNameResToJSON(value?: CheckCreateNameRes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available': value.available,
    };
}

