import { StateEnum } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class EditReportTemplateStateHandler {
  initEditReportTemplateScreen(
    this: AppStateHandler,
    templateReportId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.EditReportTemplate;
    this.state.editReportTemplateScreen.id = null;
    this.state.editReportTemplateScreen.displayName = "";
    this.state.editReportTemplateScreen.description = "";
    this.state.editReportTemplateScreen.nModelsMin = "1";
    this.state.editReportTemplateScreen.nModelsMax = "1000";
    this.state.editReportTemplateScreen.status = StateEnum.Inactive;
    this.state.editReportTemplateScreen.history = [];
    callback(this.state);

    if (this.state.clientEditScreen.id) {
      this.grexSuperAdminTemplateReportApi
        .apiGrexsuperadminClientTemplateReportRetrieve({
          clientId: this.state.clientEditScreen.id,
          templateReportId: templateReportId,
        })
        .then((response) => {
          this.state.editReportTemplateScreen.id = response.id;
          this.state.editReportTemplateScreen.displayName = response.name;
          this.state.editReportTemplateScreen.description =
            response.description;

          this.state.editReportTemplateScreen.nModelsMin = `${response.nModelsMin}`;
          this.state.editReportTemplateScreen.nModelsMax = `${
            response.nModelsMax || 1000
          }`;
          this.state.editReportTemplateScreen.status =
            response.state || StateEnum.Inactive;

          this.state.editReportTemplateScreen.history = response.history;
          callback(this.state);
        })
        .catch((error) => {
          console.log(error);
          this.state.editReportTemplateScreen.formError =
            "Error tijdens laden.";
          callback(this.state);
          setTimeout(() => {
            this.state.editReportTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        });
    }
  }

  updateEditReportTemplateScreen(
    this: AppStateHandler,
    newFormValues: {
      displayName?: string;
      description?: string;
      nModelsMin?: string;
      nModelsMax?: string;
      status?: StateEnum;
      files?: FileList | null;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.displayName !== undefined) {
      this.state.editReportTemplateScreen.displayName =
        newFormValues.displayName;
    }
    if (newFormValues.description !== undefined) {
      this.state.editReportTemplateScreen.description =
        newFormValues.description;
    }
    if (newFormValues.nModelsMin !== undefined) {
      this.state.editReportTemplateScreen.nModelsMin = newFormValues.nModelsMin;
    }
    if (newFormValues.nModelsMax !== undefined) {
      this.state.editReportTemplateScreen.nModelsMax = newFormValues.nModelsMax;
    }
    if (newFormValues.status !== undefined) {
      this.state.editReportTemplateScreen.status = newFormValues.status;
    }
    if (newFormValues.files !== undefined) {
      if (newFormValues.files !== null) {
        this.state.editReportTemplateScreen.file = newFormValues.files[0];
      }
    }
    callback(this.state);
  }

  saveUpdateEditReportTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.editReportTemplateScreen.id &&
      this.state.clientEditScreen.id &&
      this.state.editReportTemplateScreen.history.length > 0
    ) {
      if (this.state.editReportTemplateScreen.displayName !== "") {
        if (
          isNaN(parseInt(this.state.editReportTemplateScreen.nModelsMin)) ||
          isNaN(parseInt(this.state.editReportTemplateScreen.nModelsMax))
        ) {
          this.state.editReportTemplateScreen.formError =
            "Min. en max. rekenmodellen moeten gehele getallen zijn.";
          callback(this.state);
          setTimeout(() => {
            this.state.editReportTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        } else {
          if (parseInt(this.state.editReportTemplateScreen.nModelsMin) >= 1) {
            if (
              parseInt(this.state.editReportTemplateScreen.nModelsMax) <=
                2147483647 &&
              parseInt(this.state.editReportTemplateScreen.nModelsMax) >=
                parseInt(this.state.editReportTemplateScreen.nModelsMin)
            ) {
              let activeTemplate: number | null = null;
              this.state.editReportTemplateScreen.history.forEach(
                (template) => {
                  if (template.active === true) {
                    activeTemplate = template.id;
                  }
                }
              );

              if (activeTemplate) {
                this.grexSuperAdminTemplateReportApi
                  .apiGrexsuperadminClientTemplateReportUpdate({
                    clientId: this.state.clientEditScreen.id,
                    templateReportId: this.state.editReportTemplateScreen.id,
                    clientUpdateTemplateReportRequest: {
                      name: this.state.editReportTemplateScreen.displayName,
                      description:
                        this.state.editReportTemplateScreen.description,
                      nModelsMin: parseInt(
                        this.state.editReportTemplateScreen.nModelsMin
                      ),
                      nModelsMax: parseInt(
                        this.state.editReportTemplateScreen.nModelsMax
                      ),
                      state: this.state.editReportTemplateScreen.status,
                      active: activeTemplate,
                    },
                  })
                  .then((response) => {
                    console.log(response);
                    this.state.editReportTemplateScreen.successMessage =
                      "Opgeslagen";
                    callback(this.state);
                    setTimeout(() => {
                      this.state.editReportTemplateScreen.successMessage = "";
                      callback(this.state);
                    }, 2000);
                  })
                  .catch((error) => {
                    console.log(error);
                    this.state.editReportTemplateScreen.formError =
                      "Error tijdens opslaan.";
                    callback(this.state);
                    setTimeout(() => {
                      this.state.editReportTemplateScreen.formError = "";
                      callback(this.state);
                    }, 2000);
                  });
              }
            } else {
              this.state.editReportTemplateScreen.formError =
                "Het maximumaantal rekenmodellen moet kleiner dan of gelijk aan 2.147.483.647 zijn.";
              callback(this.state);
              setTimeout(() => {
                this.state.editReportTemplateScreen.formError = "";
                callback(this.state);
              }, 2000);
            }
          } else {
            this.state.editReportTemplateScreen.formError =
              "Het minimumaantal rekenmodellen moet groter dan of gelijk aan 1 zijn.";
            callback(this.state);
            setTimeout(() => {
              this.state.editReportTemplateScreen.formError = "";
              callback(this.state);
            }, 2000);
          }
        }
      } else {
        this.state.editReportTemplateScreen.formError =
          "Vul een zichtbare naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.editReportTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }

  saveEditReportTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.clientEditScreen.id &&
      this.state.editReportTemplateScreen.id
    ) {
      if (this.state.editReportTemplateScreen.file) {
        this.state.loading = false;
        callback(this.state);
        var formData = new FormData();
        formData.append("file", this.state.editReportTemplateScreen.file);
        formData.append("active", "True");

        const url = `${this.basePath()}/api/grexsuperadmin/client/${
          this.state.clientEditScreen.id
        }/template-report/${this.state.editReportTemplateScreen.id}/`;
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              this.state.loading = false;
              this.state.editReportTemplateScreen.file = null;
              this.state.editReportTemplateScreen.successMessage =
                "Upload geslaagd";
              callback(this.state);
              setTimeout(() => {
                this.state.editReportTemplateScreen.successMessage = "";
                callback(this.state);
              }, 2000);

              // Reload list
              if (
                this.state.editReportTemplateScreen.id &&
                this.state.clientEditScreen.id
              ) {
                this.grexSuperAdminTemplateReportApi
                  .apiGrexsuperadminClientTemplateReportRetrieve({
                    clientId: this.state.clientEditScreen.id,
                    templateReportId: this.state.editReportTemplateScreen.id,
                  })
                  .then((response) => {
                    this.state.editReportTemplateScreen.history =
                      response.history;
                    callback(this.state);
                  })
                  .catch((error) => {
                    console.log(error);
                    this.state.editReportTemplateScreen.formError =
                      "Error tijdens herladen scherm.";
                    callback(this.state);
                    setTimeout(() => {
                      this.state.editReportTemplateScreen.formError = "";
                      callback(this.state);
                    }, 2000);
                  });
              }
            } else {
              this.state.editReportTemplateScreen.formError =
                "Upload niet geslaagd.";
              this.state.loading = false;
              callback(this.state);
              setTimeout(() => {
                this.state.editReportTemplateScreen.formError = "";
                callback(this.state);
              }, 2000);
            }
          }
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.state.currentUser.accessToken || ""}`
        );
        xhr.send(formData);
      } else {
        this.state.editReportTemplateScreen.formError =
          "Selecteer een bestand.";
        callback(this.state);
        setTimeout(() => {
          this.state.editReportTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }

  activateReportTemplateScreen(
    this: AppStateHandler,
    historyId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.editReportTemplateScreen.id &&
      this.state.clientEditScreen.id
    ) {
      if (this.state.editReportTemplateScreen.displayName !== "") {
        if (
          isNaN(parseInt(this.state.editReportTemplateScreen.nModelsMin)) ||
          isNaN(parseInt(this.state.editReportTemplateScreen.nModelsMax))
        ) {
          this.state.editReportTemplateScreen.formError =
            "Min. en max. rekenmodellen moeten gehele getallen zijn.";
          callback(this.state);
          setTimeout(() => {
            this.state.editReportTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        } else {
          if (parseInt(this.state.editReportTemplateScreen.nModelsMin) >= 1) {
            if (
              parseInt(this.state.editReportTemplateScreen.nModelsMax) <=
                2147483647 &&
              parseInt(this.state.editReportTemplateScreen.nModelsMax) >=
                parseInt(this.state.editReportTemplateScreen.nModelsMin)
            ) {
              this.grexSuperAdminTemplateReportApi
                .apiGrexsuperadminClientTemplateReportUpdate({
                  clientId: this.state.clientEditScreen.id,
                  templateReportId: this.state.editReportTemplateScreen.id,
                  clientUpdateTemplateReportRequest: {
                    name: this.state.editReportTemplateScreen.displayName,
                    description:
                      this.state.editReportTemplateScreen.description,
                    nModelsMin: parseInt(
                      this.state.editReportTemplateScreen.nModelsMin
                    ),
                    nModelsMax: parseInt(
                      this.state.editReportTemplateScreen.nModelsMax
                    ),
                    state: this.state.editReportTemplateScreen.status,
                    active: historyId,
                  },
                })
                .then((response) => {
                  this.state.editReportTemplateScreen.successMessage =
                    "Opgeslagen";
                  callback(this.state);
                  setTimeout(() => {
                    this.state.editReportTemplateScreen.successMessage = "";
                    callback(this.state);
                  }, 2000);

                  // Reload list
                  if (
                    this.state.editReportTemplateScreen.id &&
                    this.state.clientEditScreen.id
                  ) {
                    this.grexSuperAdminTemplateReportApi
                      .apiGrexsuperadminClientTemplateReportRetrieve({
                        clientId: this.state.clientEditScreen.id,
                        templateReportId:
                          this.state.editReportTemplateScreen.id,
                      })
                      .then((response) => {
                        this.state.editReportTemplateScreen.history =
                          response.history;
                        callback(this.state);
                      })
                      .catch((error) => {
                        console.log(error);
                        this.state.editReportTemplateScreen.formError =
                          "Error tijdens herladen scherm.";
                        callback(this.state);
                        setTimeout(() => {
                          this.state.editReportTemplateScreen.formError = "";
                          callback(this.state);
                        }, 2000);
                      });
                  }
                })
                .catch((error) => {
                  this.state.editReportTemplateScreen.formError =
                    "Error tijdens opslaan.";
                  callback(this.state);
                  console.log(error);
                  setTimeout(() => {
                    this.state.editReportTemplateScreen.formError = "";
                    callback(this.state);
                  }, 2000);
                });
            } else {
              this.state.editReportTemplateScreen.formError =
                "Het maximumaantal rekenmodellen moet kleiner dan of gelijk aan 2.147.483.647 zijn.";
              callback(this.state);
              setTimeout(() => {
                this.state.editReportTemplateScreen.formError = "";
                callback(this.state);
              }, 2000);
            }
          } else {
            this.state.editReportTemplateScreen.formError =
              "Het minimumaantal rekenmodellen moet groter dan of gelijk aan 1 zijn.";
            callback(this.state);
            setTimeout(() => {
              this.state.editReportTemplateScreen.formError = "";
              callback(this.state);
            }, 2000);
          }
        }
      } else {
        this.state.editReportTemplateScreen.formError =
          "Vul een zichtbare naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.editReportTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }

  permanentlyDeleteReportTemplate(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.editReportTemplateScreen.id &&
      this.state.clientEditScreen.id
    ) {
      this.state.loading = true;
      callback(this.state);
      this.grexSuperAdminTemplateReportApi
        .apiGrexsuperadminClientTemplateReportDestroy({
          clientId: this.state.clientEditScreen.id,
          templateReportId: this.state.editReportTemplateScreen.id,
        })
        .then((response) => {
          this.state.loading = false;
          callback(this.state);
          if (this.state.clientEditScreen.id) {
            this.edit_client_init_screen(
              this.state.clientEditScreen.id,
              callback
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.state.editReportTemplateScreen.formError =
            "Error tijdens deleten.";
          this.state.loading = false;
          callback(this.state);
          setTimeout(() => {
            this.state.editReportTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        });
    }
  }

  closeAlert(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);
  }
}
