/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedMapViewerClientOptional
 */
export interface PatchedMapViewerClientOptional {
    /**
     * 
     * @type {string}
     * @memberof PatchedMapViewerClientOptional
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMapViewerClientOptional
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMapViewerClientOptional
     */
    clientSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMapViewerClientOptional
     */
    redirectUri?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMapViewerClientOptional
     */
    apiKeyId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMapViewerClientOptional
     */
    apiKey?: string;
}

/**
 * Check if a given object implements the PatchedMapViewerClientOptional interface.
 */
export function instanceOfPatchedMapViewerClientOptional(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedMapViewerClientOptionalFromJSON(json: any): PatchedMapViewerClientOptional {
    return PatchedMapViewerClientOptionalFromJSONTyped(json, false);
}

export function PatchedMapViewerClientOptionalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMapViewerClientOptional {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host': !exists(json, 'host') ? undefined : json['host'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
        'redirectUri': !exists(json, 'redirect_uri') ? undefined : json['redirect_uri'],
        'apiKeyId': !exists(json, 'api_key_id') ? undefined : json['api_key_id'],
        'apiKey': !exists(json, 'api_key') ? undefined : json['api_key'],
    };
}

export function PatchedMapViewerClientOptionalToJSON(value?: PatchedMapViewerClientOptional | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host': value.host,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
        'redirect_uri': value.redirectUri,
        'api_key_id': value.apiKeyId,
        'api_key': value.apiKey,
    };
}

